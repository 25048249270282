import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TransferResponseDto, TransfersService } from '@fi/app/client';
import { Field } from '@fi/app/ui';
import { MenuItem } from 'primeng/api';

@Injectable()
export class ProfileTransferFacadeService {
  selected: TransferResponseDto;
  fields: Array<Field>;
  cmItems: MenuItem[];
  transfers: TransferResponseDto[];
  menuItems: Array<MenuItem> = [
    {
      label: 'Lista',
      url: './list',
    },
  ];
  visibleColumns: Array<string> = [];
  tableRef = 'ProfileTransferList';
  maxRows: number = 25;
  loading: boolean = false;
  name: string = '';
  transferName: string = '';
  email: string = '';
  createdAt = {
    start: null,
    end: null,
  };
  expiresAt = {
    start: null,
    end: null,
  };

  constructor(
    private router: Router,
    private transfersService: TransfersService,
  ) {
    this.defineMenu();
    this.loading = true;
  }
  list() {
    this.transfersService
      .getTranfersByUser({
        name: this.name,
        transferName: this.transferName,
        email: this.email,
        createdAt: this.createdAt,
        expiresAt: this.expiresAt,
      })
      .subscribe((transfers) => {
        this.transfers = transfers;
        console.log(transfers);
      });
  }
  defineMenu() {
    this.cmItems = [
      {
        label: 'Dettaglio',
        icon: 'pi pi-fw pi-search',
        command: () => {
          this.router.navigate(['/home/project/sale/detail/request-data'], {
            queryParams: { id: this.selected.project._id },
          });
        },
      },
    ];
  }

  search() {
    this.list();
  }
}
