<div class="h-full flex flex-col">
  <fullstack-header></fullstack-header>

  <ng-template header-body>
    <h1 class="tx-title-1 m-2">Trasferimenti</h1>
  </ng-template>

  <fullstack-datatable
    class="flex-1"
    [options]="{ lazy: true }"
    (lazyLoad)="onLazyLoad($event)"
    [list]="facade.transfers"
    [cmItems]="facade.cmItems"
    (selected)="facade.selected = $event"
    [totalRecords]="facade.transfers?.length"
    [showColumnSelector]="true"
    [visibleColumns]="facade.visibleColumns"
    [maxRows]="facade.maxRows"
  >
    <div t-header>
      <div t-column [name]="'Nome'" field="project.data.contactNominative">
        <fullstack-input-box *t-filter [(ngModel)]="facade.name" (keyup.enter)="facade.search()"> </fullstack-input-box>
        <div *t-cell="let row">{{ row.project?.data?.contactNominative }}</div>
      </div>
      <div t-column [name]="'Destinatario'" field="email">
        <fullstack-input-box *t-filter [(ngModel)]="facade.email" (keyup.enter)="facade.search()">
        </fullstack-input-box>
        <div *t-cell="let row">
          <span *ngIf="row?.project?.data?.contactObj?.emails && row?.project?.data?.contactObj?.emails[0]">{{
            row?.email ?? row?.project?.data?.contactObj?.emails[0].email
          }}</span>
        </div>
      </div>
      <div t-column [name]="'Nome Trasferimento'" field="transfer.files[0].name">
        <fullstack-input-box *t-filter [(ngModel)]="facade.transferName" (keyup.enter)="facade.search()">
        </fullstack-input-box>
        <div *t-cell="let row">
          <span *ngIf="row?.transfer.files[0].name">{{ row?.transfer.files[0].name }}</span>
        </div>
      </div>
      <div t-column [name]="'Link'" field="transfer.url">
        <div *t-cell="let row">
          <span *ngIf="row?.transfer.url">{{ row?.transfer.url }}</span>
        </div>
      </div>
      <div t-column [name]="'Data Scadenza'" field="transfer.expiresAt">
        <fullstack-daterangepicker *t-filter (rangeChange)="facade.expiresAt = $event; facade.search()">
        </fullstack-daterangepicker>
        <div *t-cell="let row">{{ row.transfer.expiresAt | date: 'dd-MM-yyyy HH:mm' }}</div>
      </div>
      <div t-column [name]="'Data invio'" field="createdAt">
        <fullstack-daterangepicker *t-filter (rangeChange)="facade.createdAt = $event; facade.search()">
        </fullstack-daterangepicker>
        <div *t-cell="let row">{{ row.createdAt | date: 'dd-MM-yyyy HH:mm' }}</div>
      </div>
    </div>
  </fullstack-datatable>
</div>
