import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileAdditionalInfoComponent } from './components/pages/additional-info.component';
import { ProfileTabsComponent } from './components/pages/profile-tabs.component';
import { ProfileComponent } from './components/pages/profile.component';
import { ProfileTransferComponent } from './components/pages/profile-transfer/profile-transfer.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'additional-info',
        pathMatch: 'full',
      },
      {
        path: '',
        component: ProfileTabsComponent,
        children: [{ path: 'main-info', component: ProfileComponent }],
      },
      {
        path: '',
        component: ProfileTabsComponent,
        children: [{ path: 'additional-info', component: ProfileAdditionalInfoComponent }],
      },
      {
        path: '',
        component: ProfileTabsComponent,
        children: [{ path: 'transfers', component: ProfileTransferComponent }],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
