import { CommonModule } from '@angular/common';
import { Component, type OnInit } from '@angular/core';
import { PipesModule } from '@fi/app/core/pipes/pipes.module';
import { FeauturesSharedModule } from '@fi/app/features/features-shared.module';
import { SharedModule, LazyLoadEvent } from 'primeng/api';
import { ProfileTransferFacadeService } from './profile-transfer.facade.service';

@Component({
  selector: 'app-profile-transfer',
  standalone: true,
  imports: [CommonModule, SharedModule, PipesModule, FeauturesSharedModule],
  templateUrl: './profile-transfer.component.html',
  providers: [ProfileTransferFacadeService],
})
export class ProfileTransferComponent implements OnInit {
  constructor(public facade: ProfileTransferFacadeService) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onLazyLoad(_event: LazyLoadEvent) {
    this.facade.search();
  }
}
